<template>
  <div>
    <Header
      :details="{
        title: $t('clients.clients'),
        back: { name: 'clients' },
        actions: false,
      }"
    />

    <div class="sub-header"></div>

    <div class="client" id="main-container">
      <div class="flex flex-justify-content-between" style="margin-bottom: 30px">
        <div>
          <h1>{{ client.first_name }} {{ client.last_name }}</h1>
          <p>Client id: #{{ client.id }}</p>
        </div>
        <div class="flex flex-align-items-center">
          <div class="flex flex-align-items-center">
            <span>Active customer</span>
            <ToggleButton v-model="client.active" />
          </div>
          <Select
            v-if="canDeleteThings"
            class="u-ml-30"
            :options="actions"
            @input="onActionChange"
            :value="selectedAction"
          ></Select>
        </div>
      </div>

      <div id="client-form">
        <div class="grid">
          <section class="grid__i--8-12 panel">
            <div class="timeline box-container panel-content">
              <header class="flex">
                <div>
                  <h1>Client timeline</h1>
                </div>
                <div class="align-right">
                  <button @click="popupEvent(null)" class="cta trigger display-popup">{{ $t("overall.new") }} +</button>
                </div>
              </header>
              <h2>Tasks</h2>
              <ul class="follow-up-list">
                <li class="no-element" v-if="!clientEvents.length">No task</li>

                <template v-else v-for="(e, i) in tasks">
                  <li class="element" :class="e.type" :key="i + 'task'">
                    <div class="icon round-task" @click="changeEventStatus(e)"></div>
                    <div
                      class="edit flex flex-align-items-center flex-justify-content-between flex-nowrap"
                      @click="popupEvent(e)"
                      :key="e.token"
                    >
                      <div class="u-mr-20">
                        <h4 class="title">{{ e.title }}</h4>
                        <span class="date"
                          >{{ parseBeautifulDate(e.from_date) }}
                          {{ e.from_date === e.to_date ? "" : "- " + parseBeautifulDate(e.to_date) }}</span
                        >
                        <span class="time">{{ e.is_time ? " - " + e.from_time.substring(0, 5) : "" }}</span>
                        <p class="description">{{ e.description }}</p>
                      </div>
                      <div>
                        <span class="date" v-if="(e.creator && e.creator.id) || user"
                          >{{ formatFullname(e.creator && e.creator.id ? e.creator : user) }} -
                        </span>
                        <span class="date">{{
                          !e.created_at ? getTodayDateTimeString() : parseLaravelDateTime(e.created_at)
                        }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
              <h2>Historical</h2>
              <ul class="follow-up-list">
                <li class="no-element" v-if="!clientEvents.length">{{ $t("clients.no_event") }}</li>
                <template v-else v-for="e in historical">
                  <li class="element" :class="e.type" @click="popupEvent(e)" :key="e.token">
                    <div class="icon">
                      <emailSVG v-if="e.type === 'email'" />
                      <CallSVG v-else-if="e.type === 'call'" />
                      <HouseSVG v-if="e.type === 'viewing'" />
                      <HouseSVG v-if="e.type === 'home_visit_be'" />
                      <DashboardSVG v-if="e.type === 'inspection_trip'" />
                      <LocationSVG v-if="e.type === 'office_appointment'" />
                      <LocationSVG v-if="e.type === 'notary'" />
                      <EventSVG v-if="e.type === 'aftersales'" />
                    </div>
                    <div class="edit flex flex-align-items-center flex-justify-content-between flex-nowrap">
                      <div class="u-mr-20">
                        <h4 class="title">{{ e.title }}</h4>
                        <span class="date"
                          >{{ parseBeautifulDate(e.from_date) }}
                          {{ e.from_date === e.to_date ? "" : "- " + parseBeautifulDate(e.to_date) }}</span
                        >
                        <span class="time">{{ e.is_time ? " - " + e.from_time.substring(0, 5) : "" }}</span>
                        <p class="description">{{ e.description }}</p>
                      </div>
                      <div>
                        <span class="date" v-if="(e.creator && e.creator.id) || user"
                          >{{ formatFullname(e.creator && e.creator.id ? e.creator : user) }} -
                        </span>
                        <span class="date">{{
                          !e.created_at ? getTodayDateTimeString() : parseLaravelDateTime(e.created_at)
                        }}</span>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <SearchCriteriaList
              :client="client"
              :initial-items="searchCriterias"
              @change="onSearchCriteriaListChange"
            ></SearchCriteriaList>
          </section>
          <section class="grid__i--4-12" :class="'no-edition'">
            <div class="client-infos box-container">
              <p class="title--section">Contact information</p>
              <div class="flex cards-handler">
                <div class="contact-card main-card flex">
                  <div class="box flex">
                    <label>Status</label>
                    <Select v-model="client.status" :options="statusForOptions" />
                  </div>
                  <div class="box flex">
                    <label>{{ $t("overall.first_name") }}</label>
                    <input type="text" v-model="client.first_name" />
                  </div>
                  <div class="box flex">
                    <label>{{ $t("overall.last_name") }}</label>
                    <input type="text" v-model="client.last_name" />
                  </div>
                  <div class="box flex">
                    <label>NIE</label>
                    <input type="text" placeholder="Add NIE" v-model="client.identification_number" />
                  </div>
                  <div class="box flex" v-for="(email, index) in emails" :key="`email-${index}`">
                    <label>Email{{ index > 0 ? ` n°${index + 1}` : "" }}</label>
                    <input type="email" v-model="emails[index]" placeholder="Add email" />
                    <button
                      @click="addNewEmail"
                      v-if="index === emails.length - 1"
                      style="color: #4c84ff; font-weight: bold;"
                    >
                      +
                    </button>
                    <button @click="removeEmail(index)" v-if="index > 0" style="color: red; font-weight: bold;">
                      x
                    </button>
                  </div>
                  <div class="box flex" v-for="(phone, index) in phones" :key="`phone-${index}`">
                    <label>Tel{{ index > 0 ? ` n°${index + 1}` : "" }}</label>
                    <input type="text" v-model="phones[index]" placeholder="Add phone" />
                    <button
                      @click="addNewPhone"
                      v-if="index === phones.length - 1"
                      style="color: #4c84ff; font-weight: bold;"
                    >
                      +
                    </button>
                    <button @click="removePhone(index)" v-if="index > 0" style="color: red; font-weight: bold;">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-container">
              <p class="title--section">{{ $t("overall.location") }}</p>
              <div class="address">
                <AddressAutocomplete :placeholder="$t('overall.add_address')" v-model="address" />
              </div>
            </div>
            <div class="client-infos box-container">
              <p class="title--section">Client details</p>
              <div class="flex cards-handler">
                <div class="contact-card main-card flex">
                  <div class="box flex">
                    <label>Gender</label>
                    <Select
                      v-model="client.sex"
                      :options="[
                        { name: $t('overall.not_specified'), value: null },
                        { name: $t('overall.man'), value: 1 },
                        { name: $t('overall.woman'), value: 0 },
                      ]"
                    />
                  </div>
                  <div class="box flex">
                    <label>Country</label>
                    <div class="flex">
                      <input type="text" v-model="client.country" placeholder="Add country" />
                    </div>
                  </div>
                  <div class="box flex">
                    <label>{{ $t("clients.pref_language") }}</label>
                    <Select
                      :options="languagesForOptions"
                      placeholder="Select a language"
                      :default-is-empty-value="true"
                      v-model="client.language"
                    ></Select>
                  </div>
                  <div class="box flex">
                    <label>Partner name</label>
                    <input type="text" v-model="client.partner_name" placeholder="Add partner name" />
                  </div>
                  <div class="box flex">
                    <label>Source</label>
                    <input type="text" v-model="client.source" placeholder="Add source" />
                  </div>
                  <div class="box flex">
                    <label>{{ $t("overall.birthday") }}</label>
                    <Datepicker
                      v-model="client.birthday"
                      :fromDate="todayDate.getFullYear() - 18"
                      :toDate="todayDate.getFullYear() - 100"
                    />
                  </div>
                  <div class="box flex">
                    <label>Come over date</label>
                    <div class="u-flex">
                      <Select
                        :options="monthsForSelect"
                        :defaultIsEmptyValue="true"
                        :placeholder="'Select month'"
                        v-model="come_over_date_month"
                      ></Select>
                      <Select
                        :options="yearsForSelect"
                        :defaultIsEmptyValue="true"
                        :placeholder="'Select year'"
                        v-model="come_over_date_year"
                      ></Select>
                    </div>
                  </div>
                  <div class="address">
                    <div class="box full-box">
                      <Textarea v-model="client.note" placeholder="Add a note" :is-counter="true" :max="400" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-container">
              <p class="title--section">Related agents</p>
              <CollaboratorHandler
                @change="handleCollaboratorsChange"
                :assigned-agents="assignedCollaboratorsFetched"
                :agents="agents"
              />
            </div>
          </section>
        </div>
      </div>

      <div class="actions-bar">
        <template v-if="!loading">
          <button class="cancel">{{ $t("overall.cancel") }}</button>
          <button class="cta save fixed-button" @click="save">
            <span class="txt">{{ $t("overall.save") }}</span>
          </button>
        </template>
        <span class="loader" v-else></span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";
import CollaboratorHandler from "@/components/CollaboratorHandler";
import SearchCriteriaList from "./SearchCriteriaList";

import EmailSVG from "@/assets/img/email.svg";
import CallSVG from "@/assets/img/call.svg";
import LocationSVG from "@/assets/img/location.svg";
import HouseSVG from "@/assets/img/house.svg";
import DashboardSVG from "@/assets/img/dashboard.svg";
import EventSVG from "@/assets/img/event.svg";
import { mapGetters, mapActions } from "vuex";
import mixWithCanDeleteThings from "@/mixins/withCanDeleteThings";
import moment from "moment";

const ACTION_NOTHING = "nothing";
const ACTION_DELETE = "delete";

export default Vue.extend({
  name: "EditClient",
  components: {
    EmailSVG,
    CallSVG,
    LocationSVG,
    DashboardSVG,
    EventSVG,
    HouseSVG,
    CollaboratorHandler,
    SearchCriteriaList,
  },
  mixins: [mixWithCanDeleteThings],
  data() {
    return {
      actions: [
        { name: "Realize an action", value: ACTION_NOTHING },
        { name: "Delete client", value: ACTION_DELETE },
      ],
      selectedAction: ACTION_NOTHING,
      loading: false,
      currentDeal: null,
      agents: [],
      assignedCollaborators: [],
      assignedCollaboratorsFetched: [],
      emails: [""],
      phones: [""],
      monthsForSelect: [],
      yearsForSelect: [],
      come_over_date_month: null,
      come_over_date_year: null,
      client: {
        id: null,
        token: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        identification_number: null,
        reference: null,
        note: null,
        language: null,
        source: null,
        country: null,
        partner_name: null,
        active: true,
        come_over_date: undefined,
      },
      address: {
        city: "",
        country: null,
        postcode: "",
        number: "",
      },
      deals: [],
      contacts: [],
      currentDealSelected: null,
      searchCriterias: [],
      languages: [
        "french",
        "spanish",
        "english",
        "italian",
        "portuguese",
        "german",
        "dutch",
        "russian",
        "swedish",
        "sweden",
        "norwegian",
      ],
      languagesForOptions: [],
      statusForOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      status: "followUps/status",
      clientEvents: "agenda/clientEvents",
    }),
    tasks() {
      let events = this.clientEvents;
      events = events.filter((event) => !event.is_done);
      events.sort((eventA, eventB) => {
        const eventADate = moment(eventA.from_date + " " + eventA.from_time, "YYYY-MM-DD HH:mm:ss");
        const eventBDate = moment(eventB.from_date + " " + eventB.from_time, "YYYY-MM-DD HH:mm:ss");
        return eventADate.isAfter(eventBDate) ? -1 : 1;
      });
      return events;
    },
    historical() {
      let events = this.clientEvents;
      events = events.filter((event) => event.is_done);
      events.sort((eventA, eventB) => {
        const eventADate = moment(eventA.from_date + " " + eventA.from_time, "YYYY-MM-DD HH:mm:ss");
        const eventBDate = moment(eventB.from_date + " " + eventB.from_time, "YYYY-MM-DD HH:mm:ss");
        return eventADate.isAfter(eventBDate) ? -1 : 1;
      });
      return events;
    },
    user() {
      return this.$store.state.global.user;
    },
    selectDeal() {
      let options = [];
      this.deals.forEach((el, i) => {
        options.push({
          name: el.name,
          value: i,
        });
      });
      options.push({
        name: "+ " + this.$t("clients.add_deal"),
        value: "add-deal",
      });
      return options;
    },
  },
  methods: {
    ...mapActions({
      saveEvent: "agenda/saveEvent",
      setClientEvents: "agenda/setClientEvents",
      fetchStatus: "followUps/fetchStatus",
    }),
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    removePhone(index) {
      this.phones.splice(index, 1);
    },
    addNewEmail() {
      this.emails.push("");
    },
    addNewPhone() {
      this.phones.push("");
    },
    async onActionChange(value) {
      if (this.selectedAction === value) {
        return;
      }
      this.selectedAction = value;
      if (value === ACTION_DELETE) {
        const confirm = window.confirm("Are you sure you want to delete this client ?");
        if (confirm) {
          // Get all IDs ( token )
          const ids = [this.client.token];
          // Delete in backend
          const response = await this.$axios.delete("api/clients", { data: { ids } });
          if (response.status === 200) {
            console.log("[Action] delete - success", response);
            // redirect to list
            await this.$router.push({ name: "clients" });
          } else {
            console.log("[Action] delete - error", response);
          }
          // Trick for <Select> component to trigger change
          const timer = setTimeout(() => {
            this.selectedAction = ACTION_NOTHING;
            clearTimeout(timer);
          }, 10);
        } else {
          console.log("[Action] delete - user refused");
        }
      }
    },
    handleCollaboratorsChange({ collaborators }) {
      this.assignedCollaborators = collaborators;
    },
    changeDeal(index) {
      if (index === "add-deal") {
        this.addDeal();
      } else {
        this.currentDeal = this.deals[index];
        this.currentDealSelected = this.deals.length - 1;
      }
    },
    addDeal() {
      let newDeal = {
        name: this.$t("overall.deal") + " " + (this.deals.length + 1),
        favorite_properties: [],
        close_details: {
          status: null,
          date: null,
          note: null,
          selling_price: null,
          sold_price: null,
          performance: null,
          commission: null,
          property: null,
        },
        step: "inquiry",
        filters: {
          price: {
            from: 50000,
            to: 100000,
          },
          type: null,
          status: null,
          indoor_area: {
            from: 50,
            to: 100,
          },
          swimming_pool: null,
          garden: null,
          car_park: null,
          terrace: null,
          sea_sight: null,
          bedrooms: {
            from: 1,
            to: 2,
          },
          bathrooms: {
            from: 1,
            to: 2,
          },
        },
      };

      this.$axios
        .post("/api/deals", {
          client: this.client,
          deal: newDeal,
        })
        .then((res) => {
          this.deals.push(newDeal);
          this.currentDeal = this.deals[this.deals.length - 1];
          this.currentDealSelected = this.deals.length - 1;
        });
    },
    popupDeal(deal) {
      modalBus.$emit("change", {
        component: "AddDeal",
        data: {
          client: this.client,
          deal: deal,
        },
      });

      modalBus.$emit("toggle");
      modalBus.$emit("set-size", {
        width: "50%",
        "margin-top": "50px",
        height: "auto",
      });
    },
    popupEvent(event = null) {
      modalBus.$emit("change", {
        component: "AddEvent",
        data: {
          client: this.client,
          deals: this.deals,
          isNewEvent: !event,
          event: event,
        },
      });
      modalBus.$emit("toggle");
      modalBus.$emit("set-size", {
        width: "500px",
      });
    },
    async changeEventStatus(event) {
      await this.saveEvent({ event: { ...event, is_done: true } });
      notifBus.$emit("show", {
        status: "success",
        message: this.$t("clients.event_saved"),
      });
    },
    onSearchCriteriaListChange(data) {
      this.searchCriterias = data;
    },
    save() {
      this.loading = true;
      const data = {
        client: this.client,
        address: this.address,
        emails: this.emails,
        phones: this.phones,
        collaborators: this.assignedCollaborators,
        deals: this.deals,
        events: this.clientEvents,
        search_criterias: this.searchCriterias,
      };
      if (this.come_over_date_month === null || !this.come_over_date_year === null) {
        this.client.come_over_date = null;
      } else {
        const month = this.moment(this.come_over_date_month + 1, "M").format("MM");
        const year = this.come_over_date_year;
        const day = "01";
        this.client.come_over_date = `${year}-${month}-${day}`;
      }
      this.$axios
        .post("/api/clients", data)
        .then((res) => {
          this.loading = false;
          notifBus.$emit("show", {
            status: "success",
            message: this.$t("clients.notifications.saved"),
          });
        })
        .catch((res) => {
          console.log("error - response", res.response);
          console.log("error - data sent JSON", JSON.stringify(data));
          notifBus.$emit("show", {
            status: "error",
            message: res.response.data.message,
          });
        });
    },
  },
  async created() {
    await this.fetchStatus();
    this.statusForOptions = this.status.map((item) => {
      return {
        name: item.name,
        value: item.id,
      };
    });

    this.languagesForOptions = this.languages.map((language) => {
      return {
        name: language,
        value: language,
      };
    });

    modalBus.$on("delete-deal", (deal) => {
      this.deals = this.deals.filter((el) => el.token !== deal);
    });

    modalBus.$on("add-deal", (deal) => {
      if (this.deals.findIndex((e) => e.token === deal.token) === -1) this.deals.push(deal);
    });

    this.$axios.get("/api/users").then((res) => {
      this.agents = res.data;
    });

    // Make months select
    this.monthsForSelect = this.moment.months().map((month, index) => {
      return {
        name: month,
        value: index,
      };
    });

    // Make years select
    const minYear = parseInt(this.moment().format("YYYY"));
    const yearsForSelect = [];
    for (let i = 0; i < 10; i++) {
      yearsForSelect.push({
        name: minYear + i,
        value: minYear + i,
      });
    }
    this.yearsForSelect = yearsForSelect;

    if (this.$route.params.token) {
      this.$axios.get("/api/clients/" + this.$route.params.token).then((res) => {
        this.address = res.data.address;
        this.setClientEvents(res.data.events);
        this.client = res.data.client;
        this.emails = res.data.emails;
        this.phones = res.data.phones;
        this.searchCriterias = res.data.search_criterias.map((criteria) => {
          criteria.types = criteria.types.map((id) => {
            return { id };
          });
          criteria.specifications = criteria.specifications.map((id) => {
            return { id };
          });
          return criteria;
        });
        this.assignedCollaborators = res.data.collaborators;
        this.assignedCollaboratorsFetched = [...this.assignedCollaborators];
        res.data.deals.forEach((el) => {
          let temp = {
            ...el,
            favorite_properties: [],
            filters: {
              price: {
                from: el.from_price,
                to: el.to_price,
              },
              bathrooms: {
                from: el.from_bathrooms,
                to: el.to_bathrooms,
              },
              bedrooms: {
                from: el.from_bedrooms,
                to: el.to_bedrooms,
              },
              indoor_area: {
                from: el.from_indoor_area,
                to: el.to_indoor_area,
              },
            },
          };
          this.deals.push(temp);
          this.currentDeal = this.deals[0];
        });

        // Define come over date month/year
        if (this.client.come_over_date !== null && this.client.come_over_date !== "") {
          this.come_over_date_month = parseInt(this.moment(this.client.come_over_date, "YYYY-MM-DD").format("MM")) - 1;
          this.come_over_date_year = parseInt(this.moment(this.client.come_over_date, "YYYY-MM-DD").format("YYYY"));
        }
      });
    } else {
      this.$axios.get("/api/clients").then((res) => {
        let reference = res.data.length;
        const referenceLength = reference.toString().length,
          max = 6,
          prefix = "";

        for (let i = 0; i < max - referenceLength; i++) {
          reference = "0" + reference;
        }
        this.client.reference = prefix + reference;
      });
    }
  },
});
</script>
